<template>
  <div class="reset">
    <div class="main">
      <img class="main-logo" src="../../assets/images/login/logo.png" alt="" />
      <div class="main-title">欢迎注册</div>
      <div class="flex-s-b timeline-box">
        <div class="timeline-item">
          <div class="item-index s-item-index">
            <span v-if="type <= 1">1</span>
            <i v-if="type > 1" class="el-icon-check"></i>
          </div>
          <span class="item-text s-item-text">验证手机号</span>
        </div>
        <img
          class="main-timeline main-timeline1"
          src="../../assets/images/login/timeline.png"
          alt=""
        />
        <div class="timeline-item">
          <div :class="['item-index', type > 1 ? 's-item-index' : '']">
            <i v-if="type > 2" class="el-icon-check"></i>
            <span v-if="type <= 2">2</span>
          </div>
          <span :class="['item-text', type > 1 ? 's-item-text' : '']"
            >设置登录密码</span
          >
        </div>
        <img
          class="main-timeline main-timeline2"
          src="../../assets/images/login/timeline.png"
          alt=""
        />
        <div class="timeline-item main-timeline2">
          <div :class="['item-index', type > 2 ? 's-item-index' : '']">
            <span>3</span>
          </div>
          <span :class="['item-text', type > 2 ? 's-item-text' : '']"
            >注册成功</span
          >
        </div>
      </div>
      <div class="reset-content">
        <div class="step1" v-if="type == 1">
          <div class="main-ipt-box main-ipt-box1">
            <!-- 账号密码登录 -->
            <input
              class="main-ipt main-ipt2"
              v-model="name"
              type="text"
              placeholder="请输入手机号"
            />
          </div>
          <!-- 短信登录 -->
          <div class="main-sms-box main-ipt-box main-ipt-box2 flex-s-b">
            <input
              type="text"
              v-model="verification"
              class="sms-ipt"
              placeholder="请输入验证码"
            />
            <div class="main-line main-sms"></div>
            <div class="main-sms-btn" @click="getYzm" v-if="djs">
              获取验证码
            </div>
            <div class="main-sms-btn" v-else>{{ count }}s</div>
          </div>
          <div class="next-btn" @click="stepNext">下一步</div>
          <div class="step-bottom">
            <div class="protocol">
              <el-checkbox v-model="isRead"></el-checkbox>
              <span style="margin-left: 5px">已阅读并同意</span>
              <span class="protocol-btn">用户协议</span>
              &
              <span class="protocol-btn">隐私政策</span>
            </div>
            <div class="already-box" @click="already">
              <span>登录已有账号</span>
              <img
                class="already-img"
                src="../../assets/images/login/already.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="step2" v-if="type == 2">
          <div class="main-ipt-box main-ipt-box1">
            <input
              class="main-ipt main-ipt2"
              v-model="password"
              type="password"
              placeholder="请设置登录密码(6-12位字母数字下划线)"
            />
          </div>
          <div class="main-ipt-box main-ipt-box2">
            <input
              class="main-ipt main-ipt2"
              v-model="newPassword"
              type="password"
              placeholder="请再次输入密码"
            />
          </div>
          <div class="next-btn" @click="stepNext">下一步</div>
          <div class="step-bottom">
            <div class="already-box" @click="already">
              <span>登录已有账号</span>
              <img
                class="already-img"
                src="../../assets/images/login/already.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="step3" v-if="type == 3">
          <div class="next-btn" @click="stepNext">返回登录({{ second }})</div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="main-type-box">
        <span class="footer-type">关于我们</span>
        <div class="main-line main-line3"></div>
        <span class="footer-type">联系我们</span>
      </div>
      <div class="footer-title">
        <span>Copyright©2004-2021</span>
        <span> 蜂窝FW.com 版权所有</span>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import "../../assets/css/login.scss";
export default {
  name: "reset",
  data() {
    return {
      type: 1,
      second: null,
      isRead: false,
      timer: null,
      returnTimer: null,
      djs: true,
      count: 60,
      name: "",
      verification: "",
      password: "",
      newPassword: "",
      flag: false,
      steps: true,
      shure: false,
    };
  },
  mounted() {},
  destroyed() {
    clearInterval(this.timer);
    clearInterval(this.returnTimer);
  },
  methods: {
    stepNext() {
      if (this.type == 1) {
      
        if (this.name == "") {
          this.$message.error("请填写手机号");
          return;
        }
        if(this.verification == "") {
          this.$message.error("请填写验证码");
          return;
        }
        if(!this.isRead) {
          this.$message.error("请勾选用户协议！");
          return;
        }
        this.qa.queryCheckMobileCodeByCellPhone({cellPhone: this.name, mobileCode: this.verification}).then(res => {
          if(res.respCode == "0000") {
            this.type = 2;
          } else {
            this.$message.error(res.respDesc);
          }
          
        })
        
      } else if (this.type == 2) {
        var reg = /^[\w]{6,12}$/;
        if (this.password == "") {
          this.$message.error("请填写密码");
          return;
        }
        if(!reg.test(this.password)) {
          
          this.$message.error("密码必须为6-12位字母数字下划线中任意两种组合！");
          return;
        }
        if (this.newPassword != this.password) {
          this.$message.error("两次密码不一致,请重新输入");
          return;
        }
        this.qa.register({loginName: this.name, password: this.password, cellPhone: this.name, userType: 2}).then(res => {
          if(res.respCode == "0000") {
            this.type = 3;
            this.second = 5;
            this.returnTimer = setInterval(() => {
              this.second -= 1;
              console.log(this.second);
              if (this.second === 0) {
                clearInterval(this.returnTimer);
                this.$router.push({
                  path: "/",
                });
              }
            }, 1000);
          } else {
            this.$message.error(res.respDesc);
          }
        })
        
      } else if (this.type == 3) {
        this.$router.push({
          path: "/",
        });
      }
    },

    getYzm() {
      var reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if(this.name == "" || !reg.test(this.name)) {
          this.$message.error('手机号填写不正确！');
          return;
      }
      this.count = 60;
      this.djs = false;
      this.timer = setInterval(() => {
        if (this.count == 1) {
          clearInterval(this.timer);
          this.djs = true;
        }
        this.count--;
      }, 1000);
      let params = {
        mobile: this.name,
        type: 1
      };
      this.qa.sendSms(params).then((res) => {});
    },
    already() {
      this.$router.push({
        path: "/",
      });
    },
  },
};
</script>

<style scoped>
/* .reset {
	transform: scale( .8, .8); 
} */
.main {
  width: 480px;
  margin: 0 auto;
  padding: 61px 0 140px;
  text-align: center;
}
.main-logo {
  width: 93px;
  height: 91px;
}

.main-title {
  font-size: 26px;
  font-weight: 600;
  margin: 17px 0 76px;
}
.item-index {
  width: 30px;
  line-height: 30px;
  border-radius: 50px;
  background: 0;
  border: 1px solid #d8d8d8;
  color: #d8d8d8;
  margin: 0 auto 16px;
  font-size: 18px;
}
.s-item-index {
  background: #84c754;
  border: 1px solid #84c754;
  color: #fff;
  font-size: 22px;
}
.item-text {
  font-size: 14px;
  color: #d8d8d8;
}
.s-item-text {
  color: #84c754;
}
.timeline-box {
  position: relative;
  margin-bottom: 55px;
}

.main-timeline {
  width: 156px;
  height: 16px;
  position: absolute;
  top: 10px;
}
.main-timeline1 {
  left: 67px;
}
.main-timeline2 {
  left: 275px;
}

.main-ipt-box {
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  padding: 0 22px;
  border: 1px solid #d8d8d8;
  margin-bottom: 30px;
  position: relative;
}
.main-ipt {
  width: 100%;
  height: 50px;
  background: 0;
  border: 0;
}
.main-sms-box {
  align-items: center;
}
.sms-ipt {
  width: 340px;
  height: 44px;
  background: 0;
  border: 0;
}
.main-sms-btn {
  flex: 1;
  height: 48px;
  line-height: 48px;
  color: #84c754;
  font-size: 14px;
  cursor: pointer;
}
.main-line {
  width: 1px;
  height: 15px;
  background: #666666;
  margin: 0 10px;
}
.main-sms {
  height: 34px;
  background: #d8d8d8;
}
.main-ipt-box1 {
  margin-bottom: 40px;
}
.main-ipt-box2 {
  margin-bottom: 60px;
}
.next-btn {
  width: 480px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #84c754;
  color: #fff;
  font-size: 18px;
  border-radius: 8px;
  cursor: pointer;
}

.step-bottom {
  text-align: left;
  margin-top: 10px;
}
.protocol {
  font-size: 14px;
  margin-left: 6px;
  float: left;
}
.protocol-btn {
  color: #378400;
  cursor: pointer;
}
.already-img {
  width: 18px;
  height: 18px;
  display: inline-block;
  margin-left: 4px;
}
.already-box {
  height: 20px;
  line-height: 20px;
  float: right;
  display: flex;
  align-items: center;
  color: #333333;
  /* font-weight: 600; */
  cursor: pointer;
  font-size: 14px;
}

.reset >>> .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #84c754;
  border-color: #84c754;
}
.reset >>> .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #84c754;
}
.reset >>> .el-checkbox__inner:hover {
  border-color: #84c754;
}
</style>